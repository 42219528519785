// i18next-extract-mark-ns-start notification-preferences
import {Content} from 'components/Content';
import {graphql} from 'gatsby';

import React, {useEffect, useState} from 'react';
import {Field, Formik} from 'formik';
import {Helmet, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Button} from 'components/Button';
import {QueryParamProvider, StringParam, useQueryParam} from 'use-query-params';
import {ReachAdapter} from 'use-query-params/adapters/reach';
import styled from 'styled-components';
import {colors, links} from '../global.config';
import {Loader} from 'components/Loader';
import {StyledP} from 'components/StyledSx';
import {SubscribeForm, UserConfig} from 'components/SubscribeForm';
import {Flex} from '../components/Flex';
import Bold from '../components/Bold';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 32px;
  label {
    width: 100%;
  }
`;

const LoadingBackdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ErrorMessage = styled.div`
  color: #f5222d;
`;

const Topic = styled.label`
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: baseline;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
`;

const NotificationPreferencesChild: React.FC = () => {
  const {t, language} = useI18next();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [uid] = useQueryParam('uid', StringParam);
  const [h] = useQueryParam('h', StringParam);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [MonthlyNewsletter, setMonthlyNewsletter] = useState(false);
  const [PeriodicReminder, setPeriodicReminder] = useState(false);
  const [Announcements, setAnnouncements] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(false);

  const formData: UserConfig = {
    email,
    firstName,
    lastName,
    language,
    MonthlyNewsletter,
    PeriodicReminder,
    Announcements,
    unsubscribe
  };

  useEffect(() => {
    if (uid && h) {
      fetch(links.pinpointApi + `user/${uid}?h=${h}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            window.location.href = links.landing;
          }
        })
        .then((data) => {
          setEmail(data.email);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setMonthlyNewsletter(data.topics?.includes('MonthlyNewsletter'));
          setPeriodicReminder(data.topics?.includes('PeriodicReminder'));
          setAnnouncements(data.topics?.includes('Announcements'));
          setUnsubscribe(false);
          setFetching(false);
        });
    } else {
      window.location.href = links.landing;
    }
  }, []);

  useEffect(() => {
    if (unsubscribe) {
      setMonthlyNewsletter(!unsubscribe);
      setPeriodicReminder(!unsubscribe);
      setAnnouncements(!unsubscribe);
    }
  }, [unsubscribe]);

  const onSubmit = (values: any) => {
    setSuccess('');
    const topics = [];
    if (values.MonthlyNewsletter) {
      topics.push('MonthlyNewsletter');
    }
    if (values.NewBlogPost) {
      topics.push('NewBlogPost');
    }
    if (values.PeriodicReminder) {
      topics.push('PeriodicReminder');
    }
    if (values.Announcements) {
      topics.push('Announcements');
    }
    setLoading(true);
    fetch(links.pinpointApi + `user/${uid}?h=${h}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({...values, topics, language})
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.statusCode >= 400) {
          setError(result.message);
          return;
        }
        setSuccess(t('Your preferences have been updated successfully'));
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const metaTitle = t('Notification preferences');

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle} | MONEI</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Content sx={{padding: '0 20px', maxWidth: '624px', margin: '60px auto 120px'}}>
        {fetching && (
          <LoadingBackdrop>
            <Loader color={colors.pink} />
          </LoadingBackdrop>
        )}
        <Trans parent="h1">Notifications preferences</Trans>

        <Formik<UserConfig> initialValues={formData} onSubmit={onSubmit} enableReinitialize>
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <SubscribeForm disableEmail />
              <Divider />
              <Topic>
                <Field
                  type="checkbox"
                  name="unsubscribe"
                  style={{marginTop: '6px', marginRight: '6px'}}
                  onChange={() => {
                    setUnsubscribe(!unsubscribe);
                  }}
                />
                <Flex direction="column">
                  <Bold>
                    <Trans>Unsubscribe from all</Trans>
                  </Bold>
                  <Trans parent="span">You won't receive any Marketing emails from MONEI</Trans>
                </Flex>
              </Topic>
              <Button type="submit" variant="light" style={{width: '100%'}}>
                {loading ? <Loader /> : <Trans>Save</Trans>}
              </Button>
              {success && (
                <div>
                  <StyledP
                    sx={{
                      color: colors.green,
                      bottom: {all: '0', xs: '-16px'},
                      margin: '0'
                    }}>
                    {success}
                  </StyledP>
                </div>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Form>
          )}
        </Formik>
      </Content>
    </>
  );
};

const NotificationPreferencesPage = () => {
  return (
    <QueryParamProvider adapter={ReachAdapter}>
      <NotificationPreferencesChild />
    </QueryParamProvider>
  );
};

export default NotificationPreferencesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["notification-preferences", "common"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
